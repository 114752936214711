<template>
  <div class="phoneTable">
    <div style="display: flex; position: relative">
      <div
        class="btn"
        :class="this.type == '01' ? 'btnType1' : 'btnType-after1'"
        @click="ClickOrderType('01')"
      >
        累计询价排行
      </div>
      <div
        class="btn"
        :class="this.type == '02' ? 'btnType1' : 'btnType-after1'"
        @click="ClickOrderType('02')"
      >
        累计成交排行
      </div>
      <div
        class="btn"
        :class="this.type == '03' ? 'btnType1' : 'btnType-after1'"
        @click="ClickOrderType('03')"
      >
        毛利贡献排行
      </div>
    </div>
    <el-form inline size="small" style="margin-top: 20px">
      <el-form-item>
        <el-select
          v-model="searchForm.xcxStaffId"
          placeholder="请选择总监/区域"
          clearable
          size="small"
          filterable
        >
          <el-option
            v-for="item in partition"
            :key="item.name"
            :value="item.staffId"
            :label="
              item.areaName
                ? `${item.staffName}-${item.areaName}`
                : item.staffName
            "
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="searchForm.tagId"
          placeholder="选择门店标签"
          size="small"
          clearable
          filterable
        >
          <el-option
            v-for="item in selectTagList"
            :key="item.id"
            :value="item.id"
            :label="item.name"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="searchForm.storeId"
          placeholder="选择所属门店"
          size="small"
          clearable
          filterable
        >
          <el-option
            v-for="item in storeList"
            :key="item.key"
            :value="item.key"
            :label="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button
          v-for="item in dateTypeOption"
          :key="item.value"
          class="btn"
          :type="dateType == item.value ? 'primary' : 'default'"
          @click="ClickDateType(item.value)"
        >{{item.label}}</el-button>
      </el-form-item>
      <el-form-item>
        <div class="el-date-time" style="margin: 0;">
          <el-date-picker
            v-model="valueTime"
            type="daterange"
            value-format="timestamp"
            :picker-options="pickerOptions"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :default-time="['00:00:00', '23:59:59']"
          >
          </el-date-picker>
        </div>
      </el-form-item>
      <el-form-item>
        <div class="el-date-time" style="margin: 0;">
          <span class="add_time" @click="addTimeChoose">{{addTimeText}}</span>
          <el-date-picker
            style="margin-left: 30px;"
            v-model="thanValueTime"
            :picker-options="pickerOptions"
            type="daterange"
            value-format="timestamp"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :default-time="['00:00:00', '23:59:59']"
            @change="handleCurrentChange"
            v-if="addTimeTrue"
            >
          </el-date-picker>
          <span @click="addTimeCancle" v-if="addTimeTrue == true"><img src="../../../../assets/images/home/-s-zy_ico_close.png" class="close_img"></span>
        </div>
      </el-form-item>
      <el-form-item>
        <el-button icon="el-icon-search" type="primary" @click="search">查询</el-button>
      </el-form-item>
    </el-form>
    <el-table
      max-height="430"
      v-loading="tableLoading"
      :data="tableData"
      border
      style="width: 100%"
    >
      <template slot="empty">
        <div class="table_empty">
          <img
            src="../../../../assets/images/tjsjtb.png"
            style="width:200px;height:100px"
            alt=""
          />
          <span style="font-size:12px;line-height:12px;padding-left:12px"
            >暂无数据</span
          >
        </div>
      </template>
      <el-table-column label="排行" type="index" align="center" width="70">
        <template slot-scope="{ row }">
          <div class="numBG_1" v-if="row.rank === 1">1</div>
          <div class="numBG_2" v-else-if="row.rank === 2">2</div>
          <div class="numBG_3" v-else-if="row.rank === 3">3</div>
          <div v-else>{{ row.rank }}</div>
        </template>
      </el-table-column>
      <el-table-column label="员工" align="center" min-width="150px">
        <template slot-scope="{ row }">
          <div class="flex">
            <el-tag
              size="mini"
              :key="
                row.isMajor
                  ? '创建人'
                  : row.staffType == '01'
                  ? '子账号'
                  : row.staffType == '02'
                  ? '总监'
                  : row.staffType == '03'
                  ? '店长'
                  : row.staffType == '04'
                  ? '店员'
                  : '已删除'
              "
              :type="
                row.isMajor
                  ? 'danger'
                  : row.staffType == '01'
                  ? 'primary'
                  : row.staffType == '02'
                  ? 'warning'
                  : row.staffType == '03'
                  ? 'warning'
                  : row.staffType == '04'
                  ? 'success'
                  : 'info'
              "
              effect="dark"
            >
              {{
                row.isMajor
                  ? "创建人"
                  : row.staffType == "01"
                  ? "子账号"
                  : row.staffType == "02"
                  ? "总监"
                  : row.staffType == "03"
                  ? "店长"
                  : row.staffType == "04"
                  ? "店员"
                  : "已删除"
              }}
            </el-tag>
            <el-tooltip
              class="item"
              effect="dark"
              :content="row.staffName"
              placement="top"
            >
              <div class="Remarks">
                <span style="margin-left: 5px">{{ row.staffName }}</span>
              </div>
            </el-tooltip>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="所属门店" align="center">
        <template slot-scope="{ row }">
          <el-tooltip
            class="item"
            effect="dark"
            :content="row.storeName"
            placement="top"
          >
            <div class="Remarks">
              <span>{{ row.storeName || "--" }}</span>
            </div>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
        prop="inquiry"
        label="询价量(单)"
        align="center"
      >
      <template slot-scope="scope">
          <span>{{ scope.row.inquiry.chooseData }}</span>
          <div v-if="scope.row.inquiry.thanUp">
            <div v-if="scope.row.inquiry.thanUp == '00'" class="equationBlue" ><img src="../../../../assets/images/home/951@2x.png" style="width: 8px">{{ scope.row.inquiry.thanData }}%</div>
            <div v-else-if="scope.row.inquiry.thanUp == '01'" class="equationGreen"><img src="../../../../assets/images/home/icon_shqs_@2x.png" style="width: 8px">{{ scope.row.inquiry.thanData }}%</div>
            <div v-else-if="scope.row.inquiry.thanUp == '02'" class="equationRed"><img src="../../../../assets/images/home/icon_xjqs_@2x.png" style="width: 8px">{{ scope.row.inquiry.thanData }}%</div>
          </div>
        </template>
    </el-table-column>
      <el-table-column
        prop="deal"
        label="成交量(单)"
        align="center"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.deal.chooseData }}</span>
          <div v-if="scope.row.deal.thanUp">
            <div v-if="scope.row.deal.thanUp == '00'" class="equationBlue" ><img src="../../../../assets/images/home/951@2x.png" style="width: 8px">{{ scope.row.deal.thanData }}%</div>
            <div v-else-if="scope.row.deal.thanUp == '01'" class="equationGreen"><img src="../../../../assets/images/home/icon_shqs_@2x.png" style="width: 8px">{{ scope.row.deal.thanData }}%</div>
            <div v-else-if="scope.row.deal.thanUp == '02'" class="equationRed"><img src="../../../../assets/images/home/icon_xjqs_@2x.png" style="width: 8px">{{ scope.row.deal.thanData }}%</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="压价单量" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.depreciateNum.chooseData }}</span>
          <div v-if="scope.row.depreciateNum.thanUp">
            <div v-if="scope.row.depreciateNum.thanUp == '00'" class="equationBlue" ><img src="../../../../assets/images/home/951@2x.png" style="width: 8px">{{ scope.row.depreciateNum.thanData }}%</div>
            <div v-else-if="scope.row.depreciateNum.thanUp == '01'" class="equationGreen"><img src="../../../../assets/images/home/icon_shqs_@2x.png" style="width: 8px">{{ scope.row.depreciateNum.thanData }}%</div>
            <div v-else-if="scope.row.depreciateNum.thanUp == '02'" class="equationRed"><img src="../../../../assets/images/home/icon_xjqs_@2x.png" style="width: 8px">{{ scope.row.depreciateNum.thanData }}%</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="压价订单占比" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.depreciateRate.chooseData }}%</span>
          <div v-if="scope.row.depreciateRate.thanUp">
            <div v-if="scope.row.depreciateRate.thanUp == '00'" class="equationBlue" ><img src="../../../../assets/images/home/951@2x.png" style="width: 8px">{{ scope.row.depreciateRate.thanData }}%</div>
            <div v-else-if="scope.row.depreciateRate.thanUp == '01'" class="equationGreen"><img src="../../../../assets/images/home/icon_shqs_@2x.png" style="width: 8px">{{ scope.row.depreciateRate.thanData }}%</div>
            <div v-else-if="scope.row.depreciateRate.thanUp == '02'" class="equationRed"><img src="../../../../assets/images/home/icon_xjqs_@2x.png" style="width: 8px">{{ scope.row.depreciateRate.thanData }}%</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="成交率" align="center">
        <!-- <template slot-scope="{ row }">
          <span>{{ row.dealRate }}%</span>
        </template> -->
        <template slot-scope="scope">
          <span>{{ scope.row.dealRate.chooseData }}%</span>
          <div v-if="scope.row.dealRate.thanUp">
            <div v-if="scope.row.dealRate.thanUp == '00'" class="equationBlue" ><img src="../../../../assets/images/home/951@2x.png" style="width: 8px">{{ scope.row.dealRate.thanData }}%</div>
            <div v-else-if="scope.row.dealRate.thanUp == '01'" class="equationGreen"><img src="../../../../assets/images/home/icon_shqs_@2x.png" style="width: 8px">{{ scope.row.dealRate.thanData }}%</div>
            <div v-else-if="scope.row.dealRate.thanUp == '02'" class="equationRed"><img src="../../../../assets/images/home/icon_xjqs_@2x.png" style="width: 8px">{{ scope.row.dealRate.thanData }}%</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="abolish"
        label="作废订单"
        align="center"
      >
      <template slot-scope="scope">
          <span>{{ scope.row.abolish.chooseData }}</span>
          <div v-if="scope.row.abolish.thanUp">
            <div v-if="scope.row.abolish.thanUp == '00'" class="equationBlue" ><img src="../../../../assets/images/home/951@2x.png" style="width: 8px">{{ scope.row.abolish.thanData }}%</div>
            <div v-else-if="scope.row.abolish.thanUp == '01'" class="equationGreen"><img src="../../../../assets/images/home/icon_shqs_@2x.png" style="width: 8px">{{ scope.row.abolish.thanData }}%</div>
            <div v-else-if="scope.row.abolish.thanUp == '02'" class="equationRed"><img src="../../../../assets/images/home/icon_xjqs_@2x.png" style="width: 8px">{{ scope.row.abolish.thanData }}%</div>
          </div>
        </template>
    </el-table-column>
      <el-table-column
        prop="cancel"
        label="取消订单"
        align="center"
      >
      <template slot-scope="scope">
          <span>{{ scope.row.cancel.chooseData }}</span>
          <div v-if="scope.row.cancel.thanUp">
            <div v-if="scope.row.cancel.thanUp == '00'" class="equationBlue" ><img src="../../../../assets/images/home/951@2x.png" style="width: 8px">{{ scope.row.cancel.thanData }}%</div>
            <div v-else-if="scope.row.cancel.thanUp == '01'" class="equationGreen"><img src="../../../../assets/images/home/icon_shqs_@2x.png" style="width: 8px">{{ scope.row.cancel.thanData }}%</div>
            <div v-else-if="scope.row.cancel.thanUp == '02'" class="equationRed"><img src="../../../../assets/images/home/icon_xjqs_@2x.png" style="width: 8px">{{ scope.row.cancel.thanData }}%</div>
          </div>
        </template>
    </el-table-column>
      <el-table-column
        prop="returned"
        label="退回订单"
        align="center"
      >
      <template slot-scope="scope">
          <span>{{ scope.row.returned.chooseData }}</span>
          <div v-if="scope.row.returned.thanUp">
            <div v-if="scope.row.returned.thanUp == '00'" class="equationBlue" ><img src="../../../../assets/images/home/951@2x.png" style="width: 8px">{{ scope.row.returned.thanData }}%</div>
            <div v-else-if="scope.row.returned.thanUp == '01'" class="equationGreen"><img src="../../../../assets/images/home/icon_shqs_@2x.png" style="width: 8px">{{ scope.row.returned.thanData }}%</div>
            <div v-else-if="scope.row.returned.thanUp == '02'" class="equationRed"><img src="../../../../assets/images/home/icon_xjqs_@2x.png" style="width: 8px">{{ scope.row.returned.thanData }}%</div>
          </div>
        </template>
    </el-table-column>
    <el-table-column
        prop="aprNum"
        label="差异量"
        align="center"
      >
      <template slot-scope="scope">
          <span>{{ scope.row.aprNum.chooseData }}</span>
          <div v-if="scope.row.aprNum.thanUp">
            <div v-if="scope.row.aprNum.thanUp == '00'" class="equationBlue" ><img src="../../../../assets/images/home/951@2x.png" style="width: 8px">{{ scope.row.aprNum.thanData }}%</div>
            <div v-else-if="scope.row.aprNum.thanUp == '01'" class="equationGreen"><img src="../../../../assets/images/home/icon_shqs_@2x.png" style="width: 8px">{{ scope.row.aprNum.thanData }}%</div>
            <div v-else-if="scope.row.aprNum.thanUp == '02'" class="equationRed"><img src="../../../../assets/images/home/icon_xjqs_@2x.png" style="width: 8px">{{ scope.row.aprNum.thanData }}%</div>
          </div>
        </template>
    </el-table-column>
    <el-table-column
        prop="aprRate"
        label="差异率"
        align="center"
      >
      <template slot-scope="scope">
          <span>{{ scope.row.aprRate.chooseData }}%</span>
          <div v-if="scope.row.aprRate.thanUp">
            <div v-if="scope.row.aprRate.thanUp == '00'" class="equationBlue" ><img src="../../../../assets/images/home/951@2x.png" style="width: 8px">{{ scope.row.aprRate.thanData }}%</div>
            <div v-else-if="scope.row.aprRate.thanUp == '01'" class="equationGreen"><img src="../../../../assets/images/home/icon_shqs_@2x.png" style="width: 8px">{{ scope.row.aprRate.thanData }}%</div>
            <div v-else-if="scope.row.aprRate.thanUp == '02'" class="equationRed"><img src="../../../../assets/images/home/icon_xjqs_@2x.png" style="width: 8px">{{ scope.row.aprRate.thanData }}%</div>
          </div>
        </template>
    </el-table-column>
      <el-table-column
        prop="dealAmount"
        label="累计成交额(元)"
        align="center"
      >
      <template slot-scope="scope">
          <span>{{ scope.row.dealAmount.chooseData }}</span>
          <div v-if="scope.row.dealAmount.thanUp">
            <div v-if="scope.row.dealAmount.thanUp == '00'" class="equationBlue" ><img src="../../../../assets/images/home/951@2x.png" style="width: 8px">{{ scope.row.dealAmount.thanData }}%</div>
            <div v-else-if="scope.row.dealAmount.thanUp == '01'" class="equationGreen"><img src="../../../../assets/images/home/icon_shqs_@2x.png" style="width: 8px">{{ scope.row.dealAmount.thanData }}%</div>
            <div v-else-if="scope.row.dealAmount.thanUp == '02'" class="equationRed"><img src="../../../../assets/images/home/icon_xjqs_@2x.png" style="width: 8px">{{ scope.row.dealAmount.thanData }}%</div>
          </div>
        </template>
    </el-table-column>
      <el-table-column prop="useAddPrice" label="加价金额" align="center">
        <template slot="header">
          <div class="img-el-heard">
            <div>加价金额</div>
            <div>
              <el-tooltip
                class="item"
                effect="dark"
                content="加价金额=返利加价金额+红包加价金额"
                placement="top"
              >
                <img
                  src="../../../../assets/images/home/ico_ts_red_.png"
                  width="14px"
                  height="14px"
                  alt=""
                />
              </el-tooltip>
            </div>
          </div>
        </template>
        <template slot-scope="scope">
          <span>{{ scope.row.useAddPrice.chooseData }}</span>
          <div v-if="scope.row.useAddPrice.thanUp">
            <div v-if="scope.row.useAddPrice.thanUp == '00'" class="equationBlue" ><img src="../../../../assets/images/home/951@2x.png" style="width: 8px">{{ scope.row.useAddPrice.thanData }}%</div>
            <div v-else-if="scope.row.useAddPrice.thanUp == '01'" class="equationGreen"><img src="../../../../assets/images/home/icon_shqs_@2x.png" style="width: 8px">{{ scope.row.useAddPrice.thanData }}%</div>
            <div v-else-if="scope.row.useAddPrice.thanUp == '02'" class="equationRed"><img src="../../../../assets/images/home/icon_xjqs_@2x.png" style="width: 8px">{{ scope.row.useAddPrice.thanData }}%</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="forcePriceAll" label="总压价金额" align="center">
        <template slot="header">
          <div class="img-el-heard">
            <div>总压价金额</div>
            <div>
              <el-tooltip
                class="item"
                effect="dark"
                content="总压价金额=回收商报价-返利-红包-顾客成交价"
                placement="top"
              >
                <img
                  src="../../../../assets/images/home/ico_ts_red_.png"
                  width="14px"
                  height="14px"
                  alt=""
                />
              </el-tooltip>
            </div>
          </div>
        </template>
        <template slot-scope="scope">
          <span>{{ scope.row.forcePriceAll.chooseData }}</span>
          <div v-if="scope.row.forcePriceAll.thanUp">
            <div v-if="scope.row.forcePriceAll.thanUp == '00'" class="equationBlue" ><img src="../../../../assets/images/home/951@2x.png" style="width: 8px">{{ scope.row.forcePriceAll.thanData }}%</div>
            <div v-else-if="scope.row.forcePriceAll.thanUp == '01'" class="equationGreen"><img src="../../../../assets/images/home/icon_shqs_@2x.png" style="width: 8px">{{ scope.row.forcePriceAll.thanData }}%</div>
            <div v-else-if="scope.row.forcePriceAll.thanUp == '02'" class="equationRed"><img src="../../../../assets/images/home/icon_xjqs_@2x.png" style="width: 8px">{{ scope.row.forcePriceAll.thanData }}%</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="grossAll" label="总毛利" align="center">
        <template slot="header">
          <div class="img-el-heard">
            <div>总毛利</div>
            <div>
              <el-tooltip
                class="item"
                effect="dark"
                content="总毛利=回收商报价-顾客成交价"
                placement="top"
              >
                <img
                  src="../../../../assets/images/home/ico_ts_red_.png"
                  width="14px"
                  height="14px"
                  alt=""
                />
              </el-tooltip>
            </div>
          </div>
        </template>
        <template slot-scope="scope">
          <span>{{ scope.row.grossAll.chooseData }}</span>
          <div v-if="scope.row.grossAll.thanUp">
            <div v-if="scope.row.grossAll.thanUp == '00'" class="equationBlue" ><img src="../../../../assets/images/home/951@2x.png" style="width: 8px">{{ scope.row.grossAll.thanData }}%</div>
            <div v-else-if="scope.row.grossAll.thanUp == '01'" class="equationGreen"><img src="../../../../assets/images/home/icon_shqs_@2x.png" style="width: 8px">{{ scope.row.grossAll.thanData }}%</div>
            <div v-else-if="scope.row.grossAll.thanUp == '02'" class="equationRed"><img src="../../../../assets/images/home/icon_xjqs_@2x.png" style="width: 8px">{{ scope.row.grossAll.thanData }}%</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="rebate" label="公司净利" align="center">
        <template slot="header">
          <div class="img-el-heard">
            <div>公司净利</div>
            <div>
              <el-tooltip
                class="item"
                effect="dark"
                content="公司净利=公司返利利润+公司压价利润，扣除员工红包提成"
                placement="top"
              >
                <img
                  src="../../../../assets/images/home/ico_ts_red_.png"
                  width="14px"
                  height="14px"
                  alt=""
                />
              </el-tooltip>
            </div>
          </div>
        </template>
        <!-- <template slot-scope="scope">
          <span>{{ scope.row.rebate || 0 }}</span>
        </template> -->
        <template slot-scope="scope">
          <span>{{ scope.row.rebate.chooseData }}</span>
          <div v-if="scope.row.rebate.thanUp">
            <div v-if="scope.row.rebate.thanUp == '00'" class="equationBlue" ><img src="../../../../assets/images/home/951@2x.png" style="width: 8px">{{ scope.row.rebate.thanData }}%</div>
            <div v-else-if="scope.row.rebate.thanUp == '01'" class="equationGreen"><img src="../../../../assets/images/home/icon_shqs_@2x.png" style="width: 8px">{{ scope.row.rebate.thanData }}%</div>
            <div v-else-if="scope.row.rebate.thanUp == '02'" class="equationRed"><img src="../../../../assets/images/home/icon_xjqs_@2x.png" style="width: 8px">{{ scope.row.rebate.thanData }}%</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="rebateAfter" label="返利利润" align="center">
        <template slot="header">
          <div class="img-el-heard">
            <div>返利利润</div>
            <div>
              <el-tooltip
                class="item"
                effect="dark"
                content="返利利润=报价预留的利润比例*报价金额，扣除加价用掉的利润"
                placement="top"
              >
                <img
                  src="../../../../assets/images/home/ico_ts_red_.png"
                  width="14px"
                  height="14px"
                  alt=""
                />
              </el-tooltip>
            </div>
          </div>
        </template>
        <!-- <template slot-scope="scope">
          <span>{{ scope.row.rebateAfter || 0 }}</span>
        </template> -->
        <template slot-scope="scope">
          <span>{{ scope.row.rebateAfter.chooseData }}</span>
          <div v-if="scope.row.rebateAfter.thanUp">
            <div v-if="scope.row.rebateAfter.thanUp == '00'" class="equationBlue" ><img src="../../../../assets/images/home/951@2x.png" style="width: 8px">{{ scope.row.rebateAfter.thanData }}%</div>
            <div v-else-if="scope.row.rebateAfter.thanUp == '01'" class="equationGreen"><img src="../../../../assets/images/home/icon_shqs_@2x.png" style="width: 8px">{{ scope.row.rebateAfter.thanData }}%</div>
            <div v-else-if="scope.row.rebateAfter.thanUp == '02'" class="equationRed"><img src="../../../../assets/images/home/icon_xjqs_@2x.png" style="width: 8px">{{ scope.row.rebateAfter.thanData }}%</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="rebateDepreciate" label="公司压价利润" align="center">
        <template slot="header">
          <div class="img-el-heard">
            <div>公司压价利润</div>
            <div>
              <el-tooltip
                class="item"
                effect="dark"
                content="公司压价利润=全部压价金额-员工压价提成"
                placement="top"
              >
                <img
                  src="../../../../assets/images/home/ico_ts_red_.png"
                  width="14px"
                  height="14px"
                  alt=""
                />
              </el-tooltip>
            </div>
          </div>
        </template>
        <!-- <template slot-scope="scope">
          <span>{{ scope.row.rebateDepreciate || 0 }}</span>
        </template> -->
        <template slot-scope="scope">
          <span>{{ scope.row.rebateDepreciate.chooseData }}</span>
          <div v-if="scope.row.rebateDepreciate.thanUp">
            <div v-if="scope.row.rebateDepreciate.thanUp == '00'" class="equationBlue" ><img src="../../../../assets/images/home/951@2x.png" style="width: 8px">{{ scope.row.rebateDepreciate.thanData }}%</div>
            <div v-else-if="scope.row.rebateDepreciate.thanUp == '01'" class="equationGreen"><img src="../../../../assets/images/home/icon_shqs_@2x.png" style="width: 8px">{{ scope.row.rebateDepreciate.thanData }}%</div>
            <div v-else-if="scope.row.rebateDepreciate.thanUp == '02'" class="equationRed"><img src="../../../../assets/images/home/icon_xjqs_@2x.png" style="width: 8px">{{ scope.row.rebateDepreciate.thanData }}%</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="公司压价利润占比" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.forcePriceAllRate.chooseData }}%</span>
          <div v-if="scope.row.forcePriceAllRate.thanUp">
            <div v-if="scope.row.forcePriceAllRate.thanUp == '00'" class="equationBlue" ><img src="../../../../assets/images/home/951@2x.png" style="width: 8px">{{ scope.row.forcePriceAllRate.thanData }}%</div>
            <div v-else-if="scope.row.forcePriceAllRate.thanUp == '01'" class="equationGreen"><img src="../../../../assets/images/home/icon_shqs_@2x.png" style="width: 8px">{{ scope.row.forcePriceAllRate.thanData }}%</div>
            <div v-else-if="scope.row.forcePriceAllRate.thanUp == '02'" class="equationRed"><img src="../../../../assets/images/home/icon_xjqs_@2x.png" style="width: 8px">{{ scope.row.forcePriceAllRate.thanData }}%</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="staffReward" label="员工奖励(元)" align="center">
        <template slot="header">
          <div class="img-el-heard">
            <div>员工奖励</div>
            <div>
              <el-tooltip
                class="item"
                effect="dark"
                content="员工奖励=店长店员获得的成交红包+差价提成+创收奖励+分享奖励，未下单员工不计入统计。"
                placement="top"
              >
                <img
                  src="../../../../assets/images/home/ico_ts_red_.png"
                  width="14px"
                  height="14px"
                  alt=""
                />
              </el-tooltip>
            </div>
          </div>
        </template>
        <!-- <template slot-scope="scope">
          <span>{{ scope.row.staffReward }}</span>
        </template> -->
        <template slot-scope="scope">
          <span>{{ scope.row.staffReward.chooseData }}</span>
          <div v-if="scope.row.staffReward.thanUp">
            <div v-if="scope.row.staffReward.thanUp == '00'" class="equationBlue" ><img src="../../../../assets/images/home/951@2x.png" style="width: 8px">{{ scope.row.staffReward.thanData }}%</div>
            <div v-else-if="scope.row.staffReward.thanUp == '01'" class="equationGreen"><img src="../../../../assets/images/home/icon_shqs_@2x.png" style="width: 8px">{{ scope.row.staffReward.thanData }}%</div>
            <div v-else-if="scope.row.staffReward.thanUp == '02'" class="equationRed"><img src="../../../../assets/images/home/icon_xjqs_@2x.png" style="width: 8px">{{ scope.row.staffReward.thanData }}%</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="grossAll" label="员工红包" align="center">
        <template slot="header">
          <div class="img-el-heard">
            <div>员工红包</div>
            <div>
              <el-tooltip
                class="item"
                effect="dark"
                content="员工红包=成交订单发放给店长和店员的红包金额"
                placement="top"
              >
                <img
                  src="../../../../assets/images/home/ico_ts_red_.png"
                  width="14px"
                  height="14px"
                  alt=""
                />
              </el-tooltip>
            </div>
          </div>
        </template>
        <template slot-scope="scope">
          <span>{{ scope.row.staffAngPow.chooseData }}</span>
          <div v-if="scope.row.staffAngPow.thanUp">
            <div v-if="scope.row.staffAngPow.thanUp == '00'" class="equationBlue" ><img src="../../../../assets/images/home/951@2x.png" style="width: 8px">{{ scope.row.staffAngPow.thanData }}%</div>
            <div v-else-if="scope.row.staffAngPow.thanUp == '01'" class="equationGreen"><img src="../../../../assets/images/home/icon_shqs_@2x.png" style="width: 8px">{{ scope.row.staffAngPow.thanData }}%</div>
            <div v-else-if="scope.row.staffAngPow.thanUp == '02'" class="equationRed"><img src="../../../../assets/images/home/icon_xjqs_@2x.png" style="width: 8px">{{ scope.row.staffAngPow.thanData }}%</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="grossAll" label="员工差价提成" align="center">
        <template slot="header">
          <div class="img-el-heard">
            <div>员工差价提成</div>
            <div>
              <el-tooltip
                class="item"
                effect="dark"
                content="员工差价提成=总压价金额-公司压价利润"
                placement="top"
              >
                <img
                  src="../../../../assets/images/home/ico_ts_red_.png"
                  width="14px"
                  height="14px"
                  alt=""
                />
              </el-tooltip>
            </div>
          </div>
        </template>
        <template slot-scope="scope">
          <span>{{ scope.row.staffCut.chooseData }}</span>
          <div v-if="scope.row.staffCut.thanUp">
            <div v-if="scope.row.staffCut.thanUp == '00'" class="equationBlue" ><img src="../../../../assets/images/home/951@2x.png" style="width: 8px">{{ scope.row.staffCut.thanData }}%</div>
            <div v-else-if="scope.row.staffCut.thanUp == '01'" class="equationGreen"><img src="../../../../assets/images/home/icon_shqs_@2x.png" style="width: 8px">{{ scope.row.staffCut.thanData }}%</div>
            <div v-else-if="scope.row.staffCut.thanUp == '02'" class="equationRed"><img src="../../../../assets/images/home/icon_xjqs_@2x.png" style="width: 8px">{{ scope.row.staffCut.thanData }}%</div>
          </div>
        </template>
      </el-table-column>
            <!-- <el-table-column prop="afterSubsidyQuotePriceAll" label="总回收金额（含补贴）" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.afterSubsidyQuotePriceAll }}</span>
        </template>
      </el-table-column> -->
      <el-table-column prop="renewSubsidyPriceAll" label="换新补贴金额" align="center">
        <!-- <template slot-scope="scope">
          <span>{{ scope.row.renewSubsidyPriceAll }}</span>
        </template> -->
        <template slot-scope="scope">
          <span>{{ scope.row.renewSubsidyPriceAll.chooseData }}</span>
          <div v-if="scope.row.renewSubsidyPriceAll.thanUp">
            <div v-if="scope.row.renewSubsidyPriceAll.thanUp == '00'" class="equationBlue" ><img src="../../../../assets/images/home/951@2x.png" style="width: 8px">{{ scope.row.renewSubsidyPriceAll.thanData }}%</div>
            <div v-else-if="scope.row.renewSubsidyPriceAll.thanUp == '01'" class="equationGreen"><img src="../../../../assets/images/home/icon_shqs_@2x.png" style="width: 8px">{{ scope.row.renewSubsidyPriceAll.thanData }}%</div>
            <div v-else-if="scope.row.renewSubsidyPriceAll.thanUp == '02'" class="equationRed"><img src="../../../../assets/images/home/icon_xjqs_@2x.png" style="width: 8px">{{ scope.row.renewSubsidyPriceAll.thanData }}%</div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- <div class="orderPagina">
      <el-pagination
        float="right"
        background
        :current-page="this.page.pageNum"
        :page-size="this.page.pageSize"
        layout="total, prev, pager, next,jumper"
        :total="this.page.total"
        class="el-pagination-c"
        @current-change="handleCurrentChange"
      />
    </div> -->
  </div>
</template>
<script>
import _api from "@/utils/request";
import { MessageBox } from "element-ui";
import { mapState } from "vuex";

export default {
  name: "phoneTable",
  props:['id', "partition"],
  data() {
    return {
      dateTypeOption: [
        { label: '今日', value: '01' },
        { label: '昨日', value: '02' },
        { label: '本周', value: '03' },
        { label: '本月', value: '05' },
      ],
      addTimeText: '+添加对比时间',
      addTimeTrue: false,
      isThan: false,
      isThanTrue: true,
      thanValueTime: [],
      valueTime: "",
      tableLoading: false,
      // orderType: "01",
      dateType: "01",
      type: "01",
      tableData: [],
      allTableData: [],
      // storeList: [],
      page: {
        pageNum: 1,
        total: 0,
        pageSize: 100,
      },
      searchForm: {
        xcxStaffId: "",
        storeId: "",
        tagId: "",
      },
      day31: 30 * 24 * 3600 * 1000,
      pickerOptions: {
        onPick: ({ maxDate, minDate }) => {
          if (minDate && this.pickerMinDate) {
            this.pickerMinDate = null;
          } else if (minDate) {
            this.pickerMinDate = minDate.getTime();
          }
        },
        disabledDate: (time) => {
          if (this.pickerMinDate) {
            return (
              time.getTime() > this.pickerMinDate + this.day31 ||
              time.getTime() < this.pickerMinDate - this.day31
            );
          }
          return false;
        },
      },
    };
  },
  computed: {
    ...mapState({
      storeList: (state) => state.tagsView.storeList,
      selectTagList: (state) => state.tagsView.tagList,
    }),
  },
  created() {
    this.handleCurrentChange();
    // this.getStoreList();
  },
  mounted() {
    document.querySelector('.el-table .el-table__body-wrapper').addEventListener('scroll', this.handleScroll)
  },
  methods: {
    addTimeChoose () {
      this.addTimeText = '对比时间'
      this.addTimeTrue = true
      this.isThan = true
      this.isThanTrue = true
    },
    addTimeCancle () {
      this.addTimeText = '+添加对比时间'
      this.addTimeTrue = false
      this.isThan = false
      this.isThanTrue = true
      this.dateTime = []
      this.thanValueTime = []
      this.dateOn = '01'
      // this.getHomeData()
    },
    // 获取参数
    getParams() {
      return {
        dateType: this.dateType, //	今日 01 本周：03，本月：05
        endTime: this.valueTime ? this.valueTime[1] : "",
        orderType: this.type, //类型 01：价位段，02：品牌
        startTime: this.valueTime ? this.valueTime[0] : "",
        storeId: this.searchForm.storeId,
        tagId: this.searchForm.tagId,
        companyId: this.id,
        thanEndTime: this.thanValueTime ? this.thanValueTime[1] : "",
        thanStartTime: this.thanValueTime ? this.thanValueTime[0] : "",
        xcxStaffId: this.searchForm.xcxStaffId,
      }
    },
    // 表格切换页面
    handleCurrentChange(val) {
      console.log(val);
      // if (val) {
      //   this.page.pageNum = val;
      // }
      if (this.valueTime) {
        this.dateType = "";
      }
      if (!this.valueTime && !this.dateType) {
        this.dateType = "01";
      }
      this.tableLoading = true;
      // const baseRequest = {
      //   dateType: this.dateType, //	今日 01 本周：03，本月：05
      //   endTime: this.valueTime ? this.valueTime[1] : "",
      //   orderType: this.type, //类型 01：价位段，02：品牌
      //   startTime: this.valueTime ? this.valueTime[0] : "",
      //   storeId: this.storeId,
      //   companyId: this.id,
      //   thanEndTime: this.thanValueTime ? this.thanValueTime[1] : "",
      //   thanStartTime: this.thanValueTime ? this.thanValueTime[0] : "",
      //   pageNum: this.page.pageNum,
      //   pageSize: this.page.pageSize,
      //   xcxStaffId: this.xcxStaffId,
      // };
      this.page.pageNum = 1
      _api.getStaffInquiryStatistics(this.getParams()).then((res) => {
        if (res.code === 1) {
          this.allTableData = res.data
          this.tableData = res.data.slice(0,this.page.pageSize);
        }
        this.tableLoading = false;
      });
    },
    // getStoreList() {
    //   _api.getStoreSelectList({ companyId: this.id }).then((res) => {
    //     if (res.code === 1) {
    //       this.storeList = res.data;
    //     }
    //   });
    // },
    ClickOrderType(val) {
      this.type = val;
      this.page.pageNum = 1;
      this.handleCurrentChange();
    },
    ClickDateType(val) {
      this.valueTime = "";
      this.dateType = val;
      this.page.pageNum = 1;
      this.handleCurrentChange();
    },
    search() {
      this.page.pageNum = 1;
      this.handleCurrentChange();
    },
    // 导出订单方法
    exportExcel(e) {
      console.log(e, "回调");
      console.log(this.datalist, "----");
      // let params = {
      //   thanEndTime: this.thanValueTime ? this.thanValueTime[1] : "",
      //   thanStartTime: this.thanValueTime ? this.thanValueTime[0] : "",
      //   dateType: this.dateType, //	今日 01 本周：03，本月：05
      //   endTime: this.valueTime ? this.valueTime[1] : "",
      //   orderType: this.type, //类型 01：价位段，02：品牌
      //   startTime: this.valueTime ? this.valueTime[0] : "",
      //   storeId: this.storeId,
      //   companyId: this.id,
      //   xcxStaffId: this.xcxStaffId,
      // };
      _api.getStaffInquiryExcel(this.getParams()).then((res) => {
        const content = res;
        const blob = new Blob([content]);

        let reader = new FileReader(); // 创建读取文件对象
        reader.addEventListener("loadend", () => {
          //
          try {
            let data = JSON.parse(reader.result);
            if (data.code === 0) {
              MessageBox.alert(data.msg, "错误提示", {
                type: "error",
              });
            }
          } catch (err) {
            const fileName = "员工询价数据统计.xlsx";
            if ("download" in document.createElement("a")) {
              // 非IE下载
              const elink = document.createElement("a");
              elink.download = fileName;
              elink.style.display = "none";
              elink.href = URL.createObjectURL(blob);
              document.body.appendChild(elink);
              elink.click();
              URL.revokeObjectURL(elink.href); // 释放URL 对象
              document.body.removeChild(elink);
            } else {
              // IE10+下载
              navigator.msSaveBlob(blob, fileName);
            }
          }
        });
        reader.readAsText(blob, "utf-8");
      });
    },
    handleScroll(event) {
      const { scrollTop, scrollHeight, clientHeight } = event.target;
      if (scrollTop + clientHeight >= scrollHeight) {
        this.loadMoreData();
      }
    },
    loadMoreData() {
      this.page.pageNum += 1;
      this.tableData.push(...(this.allTableData.slice((this.page.pageNum -1)*this.page.pageSize,this.page.pageNum*this.page.pageSize)))
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.add_time {
    color: #20A0FF;
    font-size: 14px;
    font-weight: 400;
    margin-left: 20px;
    cursor: pointer;
  }
  .close_img {
    width: 16px;
    margin-left: 8px;
    margin-top: 2px;
    margin-right: 10px;
  }
/deep/ .el-range-separator {
  width: 10% !important;
}
.phoneTable {
  .el-button--default {
    //需要更改的按钮类型 type='default'
    background: #f9fbfd !important;
    border-color: #d9e6ff !important;
    color: #8492a6 !important;
  }
  //移入时按钮样式type='default'
  .el-button--default:hover {
    background: #fff !important;
    border-color: #45a3fc !important;
    color: #20a0ff !important;
    opacity: 0.8;
  }
  .el-button--primary {
    //需要更改的按钮类型 type='primary'
    background: #20a0ff !important;
    border-color: #20a0ff !important;
  }
  //移入时按钮样式 type='primary'
  .el-button--primary:hover {
    background: #20a0ff !important;
    border-color: #20a0ff !important;
    color: #fff !important;
    opacity: 0.8;
  }
  .btn {
    box-shadow: 2px 1px 10px 0px rgba(63, 44, 245, 0.2);
    margin: 0px 10px 0px 0;
    text-align: center;
    cursor: pointer;
  }
  .btnType1 {
    background: #0981ff;
    color: white;
    height: 32px;
    background: #20a0ff;
    border-radius: 4px;
    font-size: 14px;
    font-family: FZLanTingHei-M-GBK;
    font-weight: 400;
    color: #ffffff;
    line-height: 32px;
    padding: 0 9px;
  }
  .btnType-after1 {
    padding: 0 9px;
    height: 32px;
    background: #ffffff;
    border: 1px solid #20a0ff;
    border-radius: 4px;
    font-size: 14px;
    font-family: FZLanTingHei-M-GBK;
    font-weight: 400;
    color: #20a0ff;
    line-height: 32px;
  }
  .btnType2 {
    width: 100px;
    height: 36px;
    background: #0981ff;
    color: white;
    line-height: 36px;
    border-radius: 18px;
  }
  .btnType-after2 {
    width: 100px;
    height: 36px;
    background: #ffffff;
    color: #0981ff;
    line-height: 36px;
    border-radius: 18px;
  }
  .btnType3 {
    box-shadow: 2px 1px 10px 0px rgba(63, 44, 245, 0.2);
    text-align: center;
    cursor: pointer;
    width: 60px;
    height: 32px;
    line-height: 32px;
    background: #20a0ff;
    color: white;
    border-radius: 4px;
    font-size: 14px;
  }
  .orderPagina {
    padding: 10px 30px 0 0;
    display: flex;
    justify-content: flex-end;
  }
  .numBG_1 {
    background-image: url("../../../../assets/images/imgAll/icon_01.png");
    background-size: 100%;
    color: white;
  }
  .numBG_2 {
    background-image: url("../../../../assets/images/imgAll/icon_02.png");
    background-size: 100%;
    color: white;
  }
  .numBG_3 {
    background-image: url("../../../../assets/images/imgAll/icon_03.png");
    background-size: 100%;
    color: white;
  }
  .flex {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .flex-jb {
    justify-content: space-between;
  }
  .Remarks {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; //控制行数
    overflow: hidden;
  }
  .table_empty {
    padding: 30px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .img-el-heard {
    display: flex;
    justify-content: center;
    img {
      margin: 0px 0 -3px 3px;
    }
  }
}
.el-date-time {
  // /deep/ .el-input__inner {
  //   width: 200px !important;
  // }
  margin: 0 20px;
  /deep/ .el-range-separator {
    width: 10% !important;
  }
}
/deep/ .el-date-time .el-input__inner {
  width: 250px !important;
}

.equationBlue {
    color: #20A0FF;
  }
  .equationGreen {
    color: #49A915;
  }
  .equationRed {
    color: #FF4949;
  }
</style>
